import { Container, Group, Text, Box, Image } from '@mantine/core';
import { theme } from '~/lib/theme';
import { config } from '../lib/config-candidate';

export function Header({ withBanner = true }: { withBanner?: boolean }) {

  return (
    <>
      {withBanner && (
        <Box bg={theme.primaryColor} py="sm">
          <Container>
            <Text c="white" ta="center" fw={600} fz={{ base: "xs", md: "sm" }}>Limited Time Offer: Get Your Home Cleaned for $19!</Text>
          </Container>
        </Box>
      )}

      <Box py="xl" style={{ borderBottom: '1px solid #eee' }}>
        <Container size="lg">
          <Group justify="space-between" align="center">
            <Image src={config.brand.logoUrl} alt={config.brand.name} width="auto" height={30} />
          </Group>
        </Container>
      </Box>
    </>
  );
}